import React, { useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Helmet from "react-helmet";
import Header from "./header";
import Footer from "./footer";
import gsap from "gsap";
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";
import { CSSPlugin } from "gsap/CSSPlugin";
import Scrollbar from "smooth-scrollbar";
import Menu from "./menu";
import Contact from "./contact";
import ContactMobile from "./contactMobile";
import Newsletter from "./newsletter";
import NewsletterMobile from "./newsletterMobile";
import useNewsletter from "../zustand/useNewsletter";
import useContactForm from "../zustand/useContactForm";
import ThreeBackground from "../components/threeBackground";
import useBackgroundColor from "../zustand/useBackgroundColor";
import useReelState from "../zustand/useReelState";
import useThreeColor from "../zustand/useThreeColor";
import useMenu from "../zustand/useMenu";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { SplitText } from "gsap/SplitText";

import "../styles/styles.scss";

gsap.registerPlugin(DrawSVGPlugin);
gsap.registerPlugin(CSSPlugin);
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(SplitText);

/* Layout
============================================================================= */

export default ({ children, location }) => {
    // Config
    const { isDark, setIsDark } = useBackgroundColor();
    const { open, setOpen } = useMenu();

    const { contactOpen } = useContactForm();
    const { newsletterOpen, setNewsletterOpen } = useNewsletter();
    const { setActivePattern } = useThreeColor();
    const { showVideo } = useReelState();
    const data = useStaticQuery(graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `);

    useEffect(() => {
        document.querySelector("html").addEventListener("click", () => {
            setActivePattern();
        });
    }, []);

    useEffect(() => {
        if (contactOpen || newsletterOpen || showVideo) {
            document.querySelector("body").classList.add("noscroll");
            document.querySelector("html").classList.add("noscroll");
        } else {
            document.querySelector("body").classList.remove("noscroll");
            document.querySelector("html").classList.remove("noscroll");
        }
    }, [contactOpen, newsletterOpen, showVideo]);

    return (
        <>
            <Helmet>
                <script>
                    {`(function(d, s, id) {
var js, fjs = d.getElementsByTagName(s)[0];
if (d.getElementById(id)) return;
js = d.createElement(s); js.id = id;
js.src = "https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.0";
fjs.parentNode.insertBefore(js, fjs);
}(document, 'script', 'facebook-jssdk'));`}
                </script>
            </Helmet>
            <Contact />
            <ContactMobile />
            <Header
                siteTitle={data.site.siteMetadata.title}
                location={location}
            />
            <Menu location={location} />
            <main
                className={`w-full min-h-screen ${isDark ? "" : "is-light"}`}
                style={{ backgroundColor: "transparent" }}
            >
                {children}
            </main>

            <Newsletter />
            <NewsletterMobile />
            <form
                method="post"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                netlify
                name="contact"
                hidden
            >
                <input name="name" type="text" />
                <input name="email" type="email" />
                <input name="phone" type="phone" />
                <input name="company" type="text" />
                <input name="video" type="radio" />
                <input name="socialMedia" type="radio" />
                <input name="augmentedReality" type="radio" />
                <input name="web" type="radio" />
                <input name="content" type="radio" />
                <input name="graphicDesign" type="radio" />
                <input type="hidden" name="form-name" value="contact" />
                <input name="bot-field" type="hidden" />
                <textarea name="description" />
            </form>

            <Footer location={location} />
        </>
    );
};
