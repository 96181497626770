import create from "zustand";

/* Use  Smooth Scroll
============================================================================= */

const [useSmoothScroll] = create((set) => ({
    smooth: false,
    setSmooth: (smooth) => set((state) => ({ smooth })),
}));

/* Export
============================================================================= */

export default useSmoothScroll;
