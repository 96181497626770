import React from "react";
import useBackgroundColor from "../zustand/useBackgroundColor";

/* New Logo
============================================================================= */

const NewLogo = () => {
    // Config
    const { isDark } = useBackgroundColor();

    // Render
    return (
        <svg
            id="mitp-logo"
            className={`md:w-20 w-12 ${isDark ? "isDark" : ""}`}
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="100%"
            viewBox="0 0 202 179"
            style={{
                enableBackground: "new 0 0 182.57 154",
                cursor: "pointer",
                pointerEvents: "auto",
                fill: isDark ? "black" : "rgb(249,245,237)",
            }}
            space="preserve"
        >
            <g transform="matrix(1,0,0,1,-99.1772,-100.019)">
                <g transform="matrix(2,0,0,2,0,0)">
                    <g transform="matrix(1,0,0,1,55.9575,136.522)">
                        <path
                            d="M27.351,-54.581L-2.842,-54.581L-2.842,0L27.351,0L27.351,-54.581Z"
                            style={{
                                fill: isDark ? "black" : "rgb(249,245,237)",
                                fillRule: "nonzero",
                            }}
                        />
                    </g>
                </g>
                <g transform="matrix(2,0,0,2,0,0)">
                    <g transform="matrix(1,0,0,1,145.087,53.234)">
                        <path
                            d="M-27.351,54.581L2.842,54.581L2.842,0L-27.351,0L-27.351,54.581Z"
                            style={{
                                fill: isDark ? "black" : "rgb(249,245,237)",
                                fillRule: "nonzero",
                            }}
                        />
                    </g>
                </g>
                <g transform="matrix(2,0,0,2,0,0)">
                    <g transform="matrix(1,0,0,1,63.5197,50.1223)">
                        <path
                            d="M0,31.325C0.628,31.4 1.266,31.438 1.895,31.438C9.884,31.438 16.625,25.462 17.576,17.537C18.612,8.901 12.413,1.034 3.758,0C3.13,-0.075 2.492,-0.113 1.863,-0.113C-6.126,-0.112 -12.868,5.864 -13.818,13.788C-14.854,22.424 -8.656,30.291 0,31.325"
                            style={{
                                fill: isDark ? "black" : "rgb(249,245,237)",
                                fillRule: "nonzero",
                            }}
                        />
                    </g>
                </g>
                <g transform="matrix(2,0,0,2,0,0)">
                    <g transform="matrix(1,0,0,1,110.237,122.125)">
                        <path
                            d="M0,-57.637L-0.414,-60.353L-3.136,-59.94L-25.869,-56.488L-28.591,-56.075L-28.177,-53.359L-20.039,0.001L-19.625,2.716L-16.903,2.303L5.83,-1.149L8.552,-1.562L8.138,-4.278L0,-57.637Z"
                            style={{
                                fill: isDark ? "black" : "rgb(249,245,237)",
                                fillRule: "nonzero",
                            }}
                        />
                    </g>
                </g>
                <g transform="matrix(2,0,0,2,0,0)">
                    <g transform="matrix(-1,0,0,1,134.635,139.48)">
                        <path
                            d="M0,-31.135C8.603,-31.135 15.602,-24.152 15.602,-15.568C15.602,-6.984 8.603,0 0,0C-8.603,0 -15.602,-6.984 -15.602,-15.568C-15.602,-24.152 -8.603,-31.135 0,-31.135"
                            style={{
                                fill: isDark ? "black" : "rgb(249,245,237)",
                                fillRule: "nonzero",
                            }}
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};

/* Export
============================================================================= */

export default NewLogo;
