import React from "react";

/* Close Icon
============================================================================= */

const CloseIcon = () => {
    // Config

    // Render
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 82 82"
            style={{
                fillRule: "evenodd",
                clipRule: "evenodd",
                strokeLinejoin: "round",
                strokeMiterlimit: 2,
            }}
        >
            <g transform="matrix(1,0,0,1,-99335.6,-108488)">
                <g transform="matrix(2,0,0,2,85671,104141)">
                    <g transform="matrix(1,0,0,1,6872.13,2180.14)">
                        <path
                            d="M0,27.389L0.683,26.705L-1.046,24.977L-30.865,-4.842L-32.594,-6.572L-33.277,-5.888L-39.167,0.001L-39.85,0.684L-38.121,2.412L-6.572,33.961L0,27.389Z"
                            style={{ fill: "rgb(3,8,20)", fillRule: "nonzero" }}
                        />
                    </g>
                </g>
                <g transform="matrix(2,0,0,2,85671,104141)">
                    <g transform="matrix(1,0,0,1,6866.24,2213.42)">
                        <path
                            d="M0,-39.166L-0.683,-39.85L-2.412,-38.12L-33.96,-6.572L-33.277,-5.889L-27.388,0.001L-26.705,0.684L-24.976,-1.046L4.844,-30.865L6.573,-32.594L5.89,-33.276L0,-39.166Z"
                            style={{ fill: "rgb(3,8,20)", fillRule: "nonzero" }}
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};

/* Export
============================================================================= */

export default CloseIcon;
