import { Link } from "gatsby";
import React, { useState } from "react";
import useMenu from "../zustand/useMenu";
import Logo from "../svg/Logo";
import NewLogo from "../svg/NewLogo";
import FacebookIcon from "../svg/socials/fb";
import InstagramIcon from "../svg/socials/insta";
import LinkedInIcon from "../svg/socials/linkedin";
import useBackgroundColor from "../zustand/useBackgroundColor";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import useScrollPosition from "../components/useScrollPosition";
import _ from "lodash";

/* Header
============================================================================= */

const Header = ({ siteTitle, location }) => {
    // Config
    const [sticky, setSticky] = useState(false);

    useScrollPosition(
        ({ prevPos, currPos }) => {
            let isShow;
            if (currPos.y > -100) {
                isShow = false;
            } else {
                isShow = currPos.y < prevPos.y;
            }
            if (isShow !== sticky) setSticky(isShow);
        },
        [sticky]
    );

    // Render
    return (
        <header
            className={`${
                sticky && location.pathname !== "/" ? "header-sticky" : ""
            } w-screen flex flex-row items-start justify-between fixed top-0 lg:pl-10 lg:pr-12 md:pr-10 md:pl-8 md:pt-8 lg:pt-10 pl-4 pr-4 pt-4`}
            style={{ zIndex: 200000 }}
        >
            <AniLink
                cover
                to="/"
                direction={location === "/thoughts" ? "left" : "right"}
                onClick={(e) => {
                    if (location.pathname === "/") {
                        window.location.reload();
                    }
                    e.preventDefault();
                }}
                bg="#d3c846"
                duration={location === "/thoughts" ? 2 : 1}
            >
                <NewLogo />
            </AniLink>
            <MenuIcon />
        </header>
    );
};

/* Menu Icon
============================================================================= */

const MenuIcon = () => {
    // Config	n
    const { open, setOpen } = useMenu();
    const { isDark } = useBackgroundColor();

    // Render
    return (
        <div
            className="md:w-16 md:h-12 w-10 h-8 flex flex-col items-center justify-between cursor-pointer absolute top-0 right-0"
            id="menu-hamburger"
            style={{ position: "relative", zIndex: 1000 }}
            onClick={() => setOpen(!open)}
        >
            <div
                className={`menu-bar-one w-full md:h-3 h-2 relative ${
                    open ? "menu-bar-one-open" : ""
                } ${isDark ? "bg-black" : "bg-white"}`}
            />
            <div
                className={`menu-bar-two w-full md:h-3 h-2 ${
                    open ? "menu-bar-two-open" : ""
                } ${isDark ? "bg-black" : "bg-white"}`}
            />
            <div
                className={`menu-bar-three w-full md:h-3 h-2 relative ${
                    open ? "menu-bar-three-open" : ""
                } ${isDark ? "bg-black" : "bg-white"}`}
            />
        </div>
    );
};

/* Export
============================================================================= */

export default Header;
