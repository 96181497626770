import create from "zustand";
import _ from "lodash";

/* Initial State
============================================================================= */

const INITIAL_STATE = {
    email: "",
    phone: "",
    name: "",
    company: "",
    activeFilters: [],
    additionalInfo: "",
};

/* Use  Contact Form
============================================================================= */

const [useContactForm] = create((set) => ({
    ...INITIAL_STATE,
    contactOpen: false,
    activeSlide: 0,
    updateForm: (prop, value) => set((state) => ({ [prop]: value })),
    toggleFilter: (filter) =>
        set((state) => {
            if (_.includes(state.activeFilters, filter)) {
                return {
                    activeFilters: _.filter(state.activeFilters, (f) => {
                        return f !== filter;
                    }),
                };
            } else {
                return { activeFilters: [...state.activeFilters, filter] };
            }
        }),
    submit: () =>
        set((state) => ({
            ...INITIAL_STATE,
            activeSlide: 0,
        })),
    setActiveSlide: (slide) => set((state) => ({ activeSlide: slide })),
    setContactOpen: (contactOpen) => set((state) => ({ contactOpen })),
}));

/* Export
============================================================================= */

export default useContactForm;
