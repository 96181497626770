import { Link } from "gatsby";
import React from "react";
import useContactForm from "../zustand/useContactForm";
import useBackgroundColor from "../zustand/useBackgroundColor";
import PlayButton from "../svg/PlayButton";
import useReelState from "../zustand/useReelState";

/* Footer
============================================================================= */

const Footer = ({ siteTitle, location }) => {
    // Config
    const { contactOpen, setContactOpen } = useContactForm();
    const {
        setShowVideo,
        showVideo,
        showScroll,
        showPlayButton,
    } = useReelState();
    const { isDark } = useBackgroundColor();

    // Render
    return (
        <footer
            className="w-full fixed bottom-0 md:px-10 px-4"
            style={{ zIndex: 100 }}
        >
            <div
                className={`flex flex-row items-end justify-between ${
                    location.pathname !== "/" ? "hidden" : ""
                }`}
            >
                <div
                    id="contact-hover-footer"
                    onClick={() => setContactOpen(!contactOpen)}
                    className="flex flex-row items-center justify-start cursor-pointer md:pb-8 pb-4"
                >
                    <div id="get-in-touch-container" className="md:h-12 h-8 cursor-pointer">
                        <svg
                            height="100%"
                            viewBox="0 0 133 97"
                            id="get-in-touch-svg"
                            style={{
                                fillRule: "evenodd",
                                clipRule: "evenodd",
                                strokeLinejoin: "round",
                                strokeMiterlimit: 2,
                            }}
                        >
                            <g transform="matrix(1,0,0,1,-101.097,-1961.34)">
                                <g transform="matrix(2,0,0,2,0,0)">
                                    <g transform="matrix(1,0,0,1,113.955,983.669)">
                                        <path
                                            d="M0,42.401L-60.407,42.401L-60.407,10.788C-55.17,22.241 -43.62,30.203 -30.204,30.203C-16.787,30.203 -5.237,22.241 0,10.788L0,42.401ZM3,-3L-63.407,-3L-63.407,45.401L3,45.401L3,-3Z"
                                            style={{
                                                fill: isDark
                                                    ? "black"
                                                    : "rgb(249,245,237)",
                                                fillRule: "nonzero",
                                            }}
                                        ></path>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </div>
                    <h4
                        id="get-in-touch-text-footer"
                        className="hidden md:block pl-6 pb-1 text-xl"
                        style={{
                            fontFamily: "Kritik-Bold"
                        }}
                    >
                        GET IN TOUCH
                    </h4>
                </div>
                <div className="relative">
                    <div
                        id="play-button"
                        className={`md:h-16 h-10 md:mb-8 mb-4 cursor-pointer ${
                            showPlayButton
                                ? "opacity-100"
                                : "opacity-0 pointer-events-none"
                        }`}
                        style={{ position: "absolute", bottom: 0, right: 0, transitionDelay: "750ms" }}
                        onClick={() => {
                            setShowVideo(true);
                        }}
                    >
                        <PlayButton />
                    </div>
                    <div
                        className={`flex flex-col items-center md:pb-8 pb-4 ${
                            showScroll
                                ? " opacity-100"
                                : "opacity-0 pointer-events-none"
                        }`}
                        
                        id="scrollIcon"
                    >
                        <p
                            id="scroll-icon-label"
                            style={{
                                color: "white",
                                fontSize: "16px",
                                letterSpacing: "2px",
                                fontFamily: "Kritik-Bold",
                                transformOrigin: "50% 50%",
                                transform:
                                    "rotateZ(90deg) translateX(-35px) translateY(-5px)",
                            }}
                        >
                            SCROLL
                        </p>
                        <div className="scroll-downs">
                            <div className="mousey">
                                <div className="scroller" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

/* Export
============================================================================= */

export default Footer;
