import React, {
    useLayoutEffect,
    useEffect,
    useMemo,
    Suspense,
    useRef,
} from "react";
import gsap, { TweenMax, Back } from "gsap";
import { Canvas, useFrame, useLoader } from "react-three-fiber";
import {
    OrthographicCamera,
    PerspectiveCamera,
    Plane,
    Circle,
    Tetrahedron,
} from "drei";
import * as THREE from "three";
import Checkerboard from "../images/Checkerboard.jpg";
import useThreeColor from "../zustand/useThreeColor";
import { keyframes, easing } from "popmotion";

/* Three Background
============================================================================= */

const ThreeBackground = () => {
    // Config
    const ref = useRef();
    const { bgColor, activePattern } = useThreeColor();

    useEffect(() => {
        const clear = gsap.to(ref.current, {
            duration: 1,
            background: `linear-gradient(0deg, ${toRGBA(
                bgColor,
                100
            )} 0%, rgba(255,255,255,0) 100%)`,
        });
        gsap.to(document.querySelector("html"), {
            background: bgColor,
        });
        return () => {
            clear.kill();
        };
    }, [bgColor]);

    const toRGBA = (color, opacity) => {
        let split = color.split("");
        split.shift();
        split.shift();
        split.shift();
        split.pop();
        return `rgba${split.join("")}, ${opacity})`;
    };

    // Render
    return (
        <>
            <div className="fixed w-screen h-screen z-0">
                <Canvas concurrent noEvents gl={{
                    antialias: false,
                    colorManagement: true,
                    alpha: false
                }}>
                    <SuperPlane />

                    <EllipsesManager active={activePattern === 1} />
                    <TrianglesManager active={activePattern === 2} />
                    <group rotation={[0, 0, 0.2]} position={[0, -150, 0]}>
                        <SemicircleManager active={activePattern === 3} />
                    </group>
                    <WeirdShapeManager active={activePattern === 4} />
                    <SkewedTriangleManager active={activePattern === 5} />
                    <OrthographicCamera makeDefault />
                </Canvas>
            </div>
        </>
    );
};

/* Plane

============================================================================= */

const SuperPlane = () => {
    // Config
    const ref = useRef();
    const { bgColor } = useThreeColor();
    const material = useMemo(() => {
        return new THREE.MeshBasicMaterial({ color: bgColor });
    }, []);
    const color = useMemo(() => {
        return new THREE.Color(bgColor);
    });

    useEffect(() => {
        ref.current.material = material;
        return () => {
            ref.current = null;
        };
    }, []);

    useEffect(() => {
        color.set(bgColor);
        const clear = gsap.to(material.color, {
            r: color.r,
            g: color.g,
            b: color.b,
            duration: 1,
            ease: "power4",
        });
        return () => {
            clear.kill();
        };
    }, [bgColor]);

    return <Plane args={[10000, 10000]} position={[0, 0, -2000]} ref={ref} />;
};

/* Skewed Triangle Manager
============================================================================= */

const SkewedTriangleManager = ({ active }) => {
    // Config
    const ref = useRef();
    useFrame(() => {
        const { x, y, z } = ref.current.position;
        if (x >= 360) {
            ref.current.position.set(0, 0, 0);
        } else {
            ref.current.position.set(x + 1, y - 0.055, z);
        }
    });
    const { color } = useThreeColor();
    const material = useMemo(() => {
        return new THREE.MeshBasicMaterial({ color });
    }, []);
    const colorNew = useMemo(() => {
        return new THREE.Color(color);
    });

    useEffect(() => {
        colorNew.set(color);

        const clear = gsap.to(material.color, {
            r: colorNew.r,
            g: colorNew.g,
            b: colorNew.b,
            duration: 1,
            ease: "power4",
        });
        return () => {
            clear.kill();
        };
    }, [color]);

    // Render Row
    const renderRow = () => {
        const WeirdShapeRow = [];
        for (let i = 0; i <= 12; i++) {
            WeirdShapeRow.push([-1440 + 360 * i, 0 - 20 * i, -1]);
        }
        return WeirdShapeRow.map((position, i) => {
            return (
                <SkewedTriangle
                    key={i}
                    position={position}
                    material={material}
                />
            );
        });
    };

    // Render More
    const renderMore = () => {
        const WeirdShapeRow = [];
        for (let i = 0; i <= 12; i++) {
            WeirdShapeRow.push([-960 - 120 * i, 500 - 250 * i, -1]);
        }
        return WeirdShapeRow.map((position, i) => {
            return (
                <group key={i} position={position}>
                    {renderRow()}
                </group>
            );
        });
    };

    // Render
    return (
        <>
            <group ref={ref} position={[0, 0, 0]} visible={active}>
                {renderMore()}
            </group>
        </>
    );
};

/* Skewed Triangle
============================================================================= */

const SkewedTriangle = ({ position = [0, 0, -1], material }) => {
    const ref = useRef();
    const shape = useMemo(() => {
        const shape = new THREE.Shape();
        let x = 0;
        let y = 0;
        shape.moveTo(x, y);
        shape.lineTo(x + 120, y + 250);
        shape.lineTo(x + 480, y + 230);
        shape.lineTo(x, y);
        return shape;
    }, []);

    useEffect(() => {
        ref.current.material = material;
        return () => {
            ref.current = null;
        };
    }, []);

    // Render
    return (
        <mesh ref={ref} position={position}>
            <shapeGeometry attach="geometry" args={[shape]} />
        </mesh>
    );
};

/* Weird Shape Manager
============================================================================= */

const WeirdShapeManager = ({ active }) => {
    // Config
    const ref = useRef();
    useFrame(() => {
        const { x, y, z } = ref.current.position;
        if (x <= -210) {
            ref.current.position.set(0, 0, 0);
        } else {
            ref.current.position.set(x - 1 / 2, y - 2.1 / 2, z);
        }
    });
    const { color } = useThreeColor();
    const material = useMemo(() => {
        return new THREE.MeshBasicMaterial({ color });
    }, []);
    const colorNew = useMemo(() => {
        return new THREE.Color(color);
    });

    useEffect(() => {
        colorNew.set(color);

        const clear = gsap.to(material.color, {
            r: colorNew.r,
            g: colorNew.g,
            b: colorNew.b,
            duration: 1,
            ease: "power4",
        });
        return () => {
            clear.kill();
        };
    }, [color]);

    // Render Row
    const renderRow = () => {
        const WeirdShapeRow = [];
        for (let i = 0; i <= 12; i++) {
            WeirdShapeRow.push([420, 1050 - 325.5 * i, -1]);
        }
        return WeirdShapeRow.map((position, i) => {
            return (
                <WeirdShape key={i} position={position} material={material} />
            );
        });
    };

    // Render More
    const renderMore = () => {
        const WeirdShapeRow = [];
        for (let i = 0; i <= 12; i++) {
            WeirdShapeRow.push([840 - 210 * i, 920 - 115.5 * i, -1]);
        }
        return WeirdShapeRow.map((position, i) => {
            return (
                <group key={i} position={position}>
                    {renderRow()}
                </group>
            );
        });
    };

    // Render
    return (
        <>
            <group ref={ref} position={[0, 0, 0]} visible={active}>
                {renderMore()}
            </group>
        </>
    );
};

/* Weird Shape
============================================================================= */

const WeirdShape = ({ position = [0, 0, -1], material }) => {
    const ref = useRef();
    const shape = useMemo(() => {
        const shape = new THREE.Shape();
        let x = 0;
        let y = 0;
        let height = 210;
        let width = 210;
        shape.moveTo(x, y + height);
        shape.lineTo(x + width, y + height * 0.55);
        shape.lineTo(x + width, y);
        shape.lineTo(x, y);
        return shape;
    }, []);

    useEffect(() => {
        ref.current.material = material;
        return () => {
            ref.current = null;
        };
    }, []);

    // Render
    return (
        <mesh ref={ref} position={position}>
            <shapeGeometry attach="geometry" args={[shape]} />
        </mesh>
    );
};

/* Semicircle Manager
============================================================================= */

const SemicircleManager = ({ active }) => {
    // Config
    const ref = useRef();
    useFrame(() => {
        const { x, y, z } = ref.current.position;
        if (y <= -800) {
            ref.current.position.set(0, 0, 0);
        } else {
            ref.current.position.set(x - 0.5, y - 1, z);
        }
    });
    const { color } = useThreeColor();
    const material = useMemo(() => {
        return new THREE.MeshBasicMaterial({ color });
    }, []);
    const colorNew = useMemo(() => {
        return new THREE.Color(color);
    });

    useEffect(() => {
        colorNew.set(color);

        const clear = gsap.to(material.color, {
            r: colorNew.r,
            g: colorNew.g,
            b: colorNew.b,
            duration: 1,
            ease: "power4",
        });
        return () => {
            clear.kill();
        };
    }, [color]);

    // Render Rows
    const renderRows = () => {
        const EllipseRow = [];
        for (let i = 0; i <= 10; i++) {
            EllipseRow.push([1000 + 200 * i, -400 + 200 * i, -1]);
        }
        return EllipseRow.map((position, i) => {
            return (
                <SemicircleRow
                    key={i}
                    position={position}
                    material={material}
                />
            );
        });
    };

    // Render
    return (
        <>
            <group ref={ref} position={[0, 0, 0]} visible={active}>
                {renderRows()}
            </group>
        </>
    );
};

/* Semicircle Row
============================================================================= */

const SemicircleRow = ({ position = [2000, 0, 0], material }) => {
    // Render Row
    const renderRow = () => {
        const EllipseRow = [];
        for (let i = 0; i <= 10; i++) {
            EllipseRow.push([0 + 400 * i, 0, -1]);
        }
        return EllipseRow.map((position, i) => {
            return (
                <Semicircle key={i} position={position} material={material} />
            );
        });
    };

    // Render
    return (
        <group position={position} rotation={[0, 0, 3.14159]}>
            {renderRow()}
        </group>
    );
};

/* Semicircle
============================================================================= */

const Semicircle = ({ position = [0, 0, -1], material }) => {
    const ref = useRef();

    useEffect(() => {
        ref.current.material = material;
        return () => {
            ref.current = null;
        };
    }, []);

    // Render
    return (
        <Circle
            ref={ref}
            args={[200, 30, 0, 3.15]}
            position={position}
            rotation={[0, 0, -0.004]}
        />
    );
};

/* Ellipses Manager
============================================================================= */

const EllipsesManager = ({ active }) => {
    // Config
    const ref = useRef();
    useFrame(() => {
        const { x, y, z } = ref.current.position;
        if (x >= 250 && y <= -225) {
            ref.current.position.set(0, 0, 0);
        } else {
            ref.current.position.set(x + 1, y - 0.9, z);
        }
    });
    const { color } = useThreeColor();
    const material = useMemo(() => {
        return new THREE.MeshBasicMaterial({ color });
    }, []);
    const colorNew = useMemo(() => {
        return new THREE.Color(color);
    });

    useEffect(() => {
        colorNew.set(color);

        const clear = gsap.to(material.color, {
            r: colorNew.r,
            g: colorNew.g,
            b: colorNew.b,
            duration: 1,
            ease: "power4",
        });
        return () => {
            clear.kill();
        };
    }, [color]);

    // Render
    return (
        <>
            <group ref={ref} position={[0, 0, 0]} visible={active}>
                <Ellipses position={[-500, -950, 0]} material={material} />
                <Ellipses position={[-750, -725, 0]} material={material} />
                <Ellipses material={material} />
                <Ellipses position={[-1250, -275, 0]} material={material} />
                <Ellipses position={[-1500, -50, 0]} material={material} />
                <Ellipses position={[-1750, 175, 0]} material={material} />
                <Ellipses position={[-2000, 400, 0]} material={material} />
            </group>
        </>
    );
};

/* Ellipses
============================================================================= */

const Ellipses = ({ position = [-1000, -500, 0], material }) => {
    // Config

    // Render Row
    const renderRow = () => {
        const EllipseRow = [];
        for (let i = 0; i <= 10; i++) {
            EllipseRow.push([0 + 400 * i, 0 + 125 * i, -1]);
        }
        return EllipseRow.map((position, i) => {
            return (
                <EllipseShape key={i} position={position} material={material} />
            );
        });
    };

    // Render
    return <group position={position}>{renderRow()}</group>;
};

/* Ellipse Shape
============================================================================= */

const EllipseShape = ({ position = [0, 0, -1], material }) => {
    // Config
    const ref = useRef();

    useEffect(() => {
        ref.current.material = material;
        return () => {
            ref.current = null;
        };
    }, []);

    // Render
    return (
        <Circle
            ref={ref}
            args={[100, 50]}
            scale={[2.75, 0.95, 1]}
            position={position}
            rotation={[0, 0, 0]}
        />
    );
};

/* Triangles Manager
============================================================================= */

const TrianglesManager = ({ active }) => {
    // Config
    const ref = useRef();
    useFrame(() => {
        const { x, y, z } = ref.current.position;
        if (x <= -4 * 255) {
            ref.current.position.set(0, 0, 0);
        } else {
            ref.current.position.set(x - 4 / 8, y - 6.0654 / 8, z);
        }
    });

    // Render
    return (
        <>
            <group ref={ref} position={[0, 0, 0]} visible={active}>
                <Triangles />
            </group>
        </>
    );
};

/* Triangles
============================================================================= */

const Triangles = ({ position = [-1000, -500, 0] }) => {
    // Config
    const { color } = useThreeColor();
    const material = useMemo(() => {
        return new THREE.MeshBasicMaterial({ color });
    }, []);
    const colorNew = useMemo(() => {
        return new THREE.Color(color);
    });

    useEffect(() => {
        colorNew.set(color);

        const clear = gsap.to(material.color, {
            r: colorNew.r,
            g: colorNew.g,
            b: colorNew.b,
            duration: 0.5,
            ease: "power4",
        });
        return () => {
            clear.kill();
        };
    }, [color]);

    // Render Row
    const renderRow = () => {
        const DoubleTriangleRow = [];
        for (let i = 0; i <= 16; i++) {
            DoubleTriangleRow.push([-380 + 380 * i, 3000, -300]);
        }
        return DoubleTriangleRow.map((position, i) => {
            return (
                <DoubleTriangle
                    key={i}
                    position={position}
                    material={material}
                />
            );
        });
    };

    // Render More
    const renderMore = () => {
        const MoreTriangleRow = [];
        for (let i = 0; i <= 16; i++) {
            MoreTriangleRow.push([-746 + 373 * i, 0 - 500 * i, -200]);
        }
        return MoreTriangleRow.map((position, i) => {
            return <group position={position}>{renderRow()}</group>;
        });
    };

    // Render
    return (
        <group position={position} rotation={[0, 0, 0.075]}>
            {renderMore()}
        </group>
    );
};

/* Double Triangle
============================================================================= */

const DoubleTriangle = ({ position = [0, 0, -1], material }) => {
    // Render Row
    const renderRow = () => {
        const TriangleRow = [];
        for (let i = 0; i <= 1; i++) {
            TriangleRow.push([400 + 136 * i, 800 - 240 * i, -1000]);
        }
        return TriangleRow.map((position, i) => {
            return (
                <TriangleShape
                    key={i}
                    position={position}
                    material={material}
                />
            );
        });
    };

    // Render
    return (
        <group position={position} rotation={[0, 0, 3.15]}>
            {renderRow()}
        </group>
    );
};

/* Triangle Shape
============================================================================= */

const TriangleShape = ({ position = [0, 0, -1], material }) => {
    // Config
    const ref = useRef();

    useEffect(() => {
        ref.current.material = material;
        return () => {
            ref.current = null;
        };
    }, []);

    // Render
    return (
        <Tetrahedron
            ref={ref}
            args={[200]}
            position={position}
            scale={[1, 1.25, 1]}
            rotation={[0, 0.785398, 0]}
        />
    );
};

/* Export
============================================================================= */

export default ThreeBackground;
