import React, { useState, useEffect, useRef } from "react";
import useContactForm from "../zustand/useContactForm";
import CloseIcon from "../svg/Close";
import gsap, { TweenMax, Back } from "gsap";
import { SplitText } from "gsap/SplitText";
import Typed from "typed.js";
import _ from "lodash";
import axios from "axios";

/* Contact
============================================================================= */

const Contact = () => {
    // Config
    const {
        contactOpen,
        setContactOpen,
        activeSlide,
        setActiveSlide,
        activeFilters,
        additionalInfo,
        email,
        phone,
        name,
        company,
        updateForm,
    } = useContactForm();

    useEffect(() => {
        let typed = new Typed("#contact-typeout", {
            strings: ["progress", "waves", "leaps", "moves", "noise", "change"],
            smartBackspace: true,
            typeSpeed: 100,
            backSpeed: 50,
            loop: true,
            loopCount: Infinity,
        });
    }, []);

    // On Submit
    const onSubmit = async () => {
        const encode = (data) => {
            return Object.keys(data)
                .map(
                    (key) =>
                        encodeURIComponent(key) +
                        "=" +
                        encodeURIComponent(data[key])
                )
                .join("&");
        };
        await axios
            .post(
                "/",
                encode({
                    email,
                    phone,
                    name,
                    company,
                    description: additionalInfo,
                    video: _.includes(activeFilters, "Video"),
                    socialMedia: _.includes(activeFilters, "Social Media"),
                    augmentedReality: _.includes(
                        activeFilters,
                        "Augmented Reality"
                    ),
                    web: _.includes(activeFilters, "Web"),
                    content: _.includes(activeFilters, "Content"),
                    graphicDesign: _.includes(activeFilters, "Graphic Design"),
                    "form-name": "contact",
                }),
                {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                }
            )
            .then(() =>
                alert(
                    "Thanks for contacting us! We will get in touch with you shortly."
                )
            )
            .catch((error) => alert(error));
    };

    // Render
    return (
        <div
            id="contact-form"
            onClick={() => setContactOpen(false)}
            style={{ zIndex: 10000 }}
            className={`fixed w-screen h-screen flex items-center justify-center ${
                contactOpen ? "contact-form-visible" : ""
            }`}
        >
            <div
                id="contact-modal-container"
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                <div
                    id="contact-close"
                    className="h-6 w-6"
                    onClick={() => setContactOpen(false)}
                >
                    <CloseIcon />
                </div>
                <form
                    action=""
                    className="h-full relative"
                    style={{ overflow: "hidden" }}
                >
                    <ContactSlideOne
                        activeSlide={activeSlide}
                        setActiveSlide={setActiveSlide}
                    />
                    <ContactSlideTwo
                        activeSlide={activeSlide}
                        setActiveSlide={setActiveSlide}
                        onSubmit={onSubmit}
                    />
                </form>
            </div>
        </div>
    );
};

/* Contact Slide One
============================================================================= */

const ContactSlideOne = ({ activeSlide, setActiveSlide }) => {
    // Config
    const { email, phone, name, company, updateForm } = useContactForm();

    // Render
    return (
        <div
            className="form-slide-container flex flex-col items-start justify-between md:h-full absolute py-6 px-8"
            style={{
                right: `${activeSlide === 0 ? "0%" : "100%"}`,
            }}
        >
            <div className="w-full h-full">
                <div className="flex flex-col items-start justify-start">
                    <h2 className="lg:text-4xl text-3xl slide-heading uppercase leading-tight">
                        Let's make some <br />
                        <span id="contact-typeout"></span>
                        <br className="md:hidden" /> together.
                    </h2>
                    <p className="slide-subheading pt-4 pb-6 lg:text-lg text-base md:w-4/5 w-full">
                        Let's get this ball rolling! Complete the fields below
                        <br />
                        and we'll be in touch within one business day.
                    </p>
                </div>
                <div className="flex flex-col items-start justify-start">
                    <FormInputContainer label="What should we call you?">
                        <FormTextInput
                            placeholder="Your Name Here"
                            type="text"
                            name="name"
                            value={name}
                            marginRight="0px"
                            onChange={updateForm}
                        />
                    </FormInputContainer>
                    <FormInputContainer label="Company / Organisation Name (optional)">
                        <FormTextInput
                            placeholder="Your Company Ltd"
                            type="text"
                            name="company"
                            value={company}
                            marginRight="0px"
                            onChange={updateForm}
                        />
                    </FormInputContainer>
                    <FormInputContainer label="How can we get in touch?">
                        <FormTextInput
                            placeholder="Email Address"
                            type="email"
                            name="email"
                            value={email}
                            marginRight="25px"
                            onChange={updateForm}
                        />
                        <FormTextInput
                            placeholder="Phone Number"
                            type="phone"
                            name="phone"
                            value={phone}
                            marginRight="0px"
                            onChange={updateForm}
                        />
                    </FormInputContainer>
                </div>
            </div>
            <div className="flex flex-row items-center justify-between w-full">
                <div />
                <button
                    className={`form-button px-8 py-2 text-xl ${
                        !name || (!email && !phone)
                            ? "pointer-events-none opacity-50"
                            : ""
                    }`}
                    onClick={(e) => {
                        e.preventDefault();
                        setActiveSlide(1);
                    }}
                >
                    NEXT
                </button>
            </div>
        </div>
    );
};

/* Contact Slide Two
============================================================================= */

const ContactSlideTwo = ({ activeSlide, setActiveSlide, onSubmit }) => {
    // Config
    const {
        activeFilters,
        toggleFilter,
        name,
        submit,
        setContactOpen,
        additionalInfo,
    } = useContactForm();

    // Render
    return (
        <div
            className="form-slide-container flex flex-col items-start justify-between md:h-full absolute py-6 px-8"
            style={{
                left: `${activeSlide === 1 ? "0%" : "100%"}`,
            }}
        >
            <div className="w-full" style={{ height: "65vh" }}>
                <div className="flex flex-col items-start justify-start">
                    <h2 className="lg:text-4xl text-3xl slide-heading uppercase leading-tight">
                        Hi, <span style={{ color: "#d3c846" }}>{name}.</span>
                        <br />
                        Anything else before we get started?
                    </h2>
                    <p className="slide-subheading pt-4 pb-6 lg:text-lg text-base w-4/5">
                        Don't hold back, we love the details.
                    </p>
                </div>
                <p className="form-label lg:pb-4 pb-2 lg:text-xl text-xl">
                    What are you looking for?
                </p>
                <div className="w-full grid grid-cols-3 gap-4">
                    <ContactInterestItem
                        label="Video"
                        color="#FF5A23"
                        active={_.includes(activeFilters, "Video")}
                        onClick={toggleFilter}
                    />
                    <ContactInterestItem
                        label="Social Media"
                        color="#D3C846"
                        active={_.includes(activeFilters, "Social Media")}
                        onClick={toggleFilter}
                    />
                    <ContactInterestItem
                        label="Augmented Reality"
                        color="#C69AF2"
                        active={_.includes(activeFilters, "Augmented Reality")}
                        onClick={toggleFilter}
                    />
                    <ContactInterestItem
                        label="Web"
                        color="#48B0CC"
                        active={_.includes(activeFilters, "Web")}
                        onClick={toggleFilter}
                    />
                    <ContactInterestItem
                        label="Content"
                        color="#F49608"
                        active={_.includes(activeFilters, "Content")}
                        onClick={toggleFilter}
                    />
                    <ContactInterestItem
                        label="Graphic Design"
                        color="#1BB295"
                        active={_.includes(activeFilters, "Graphic Design")}
                        onClick={toggleFilter}
                    />
                </div>
                <FormTextArea />
            </div>
            <div className="flex flex-row items-center justify-between w-full">
                <button
                    className="form-button px-8 py-2 text-xl"
                    onClick={(e) => {
                        e.preventDefault();
                        setActiveSlide(0);
                    }}
                >
                    PREVIOUS
                </button>
                <button
                    className={`form-button px-8 py-2 text-xl ${
                        !activeFilters.length && !additionalInfo
                            ? "pointer-events-none opacity-50"
                            : ""
                    }`}
                    onClick={(e) => {
                        e.preventDefault();
                        setContactOpen(false);
                        onSubmit();
                        submit();
                    }}
                >
                    DONE
                </button>
            </div>
        </div>
    );
};

/* Form Text Area
============================================================================= */

const FormTextArea = () => {
    // Config
    const { additionalInfo, updateForm } = useContactForm();
    // Render
    return (
        <>
            <p className="form-label lg:pb-4 pb-2 lg:text-xl text-xl pt-8">
                Any additional info?
            </p>

            <textarea
                className="w-full px-3 py-2 flex-1 h-48"
                placeholder="Who do you want to target? What do you want to achieve? Any special requirements?"
                value={additionalInfo}
                onChange={(e) => updateForm("additionalInfo", e.target.value)}
                style={{
                    borderWidth: "2px",
                    borderColor: "black",
                    resize: "none",
                    backgroundColor: "transparent",
                    fontFamily: "Montserrat-Regular",
                }}
            ></textarea>
        </>
    );
};

/* Contact Interest Item
============================================================================= */

const ContactInterestItem = ({ label, color, active, onClick }) => {
    // Config

    // Render
    return (
        <div
            className="w-full px-3 py-2 cursor-pointer"
            onClick={() => onClick(label)}
            style={{
                backgroundColor: active ? color : "",
                borderWidth: "2px",
                borderColor: active ? color : "black",
            }}
        >
            <h4
                className="font-bold tracking-wide"
                style={{ fontFamily: "Montserrat-Regular" }}
            >
                {label}
            </h4>
        </div>
    );
};

/* Form Input Container
============================================================================= */

const FormInputContainer = ({ children, label }) => {
    // Config

    // Render
    return (
        <div className="form-input-container">
            <p className="form-label lg:pb-4 pb-2 lg:text-xl text-xl">
                {label}
            </p>
            <div className="flex md:flex-row flex-col md:items-center items-start md:justify-between justify-start">
                {children}
            </div>
        </div>
    );
};

/* Text Input
============================================================================= */

const FormTextInput = ({
    placeholder,
    type,
    name,
    value,
    marginRight,
    onChange,
}) => {
    // Config

    // Render
    return (
        <input
            className="form-text-input"
            placeholder={placeholder}
            type={type}
            name={name}
            value={value}
            onChange={(e) => onChange(name, e.target.value)}
            style={{
                marginRight,
            }}
        />
    );
};

/* Button Container
============================================================================= */

const ButtonContainer = ({ label }) => {
    // Config

    // Render
    return (
        <div className="form-button-container flex flex-row items-center justify-between w-full">
            <div />
            <button className="form-button">{label}</button>
        </div>
    );
};

/* Export
============================================================================= */

export default Contact;
