import create from "zustand";

/* Use Background Color
============================================================================= */

const [useBackgroundColor] = create((set) => ({
    isDark: false,
    setIsDark: (isDark) => set((state) => ({ isDark })),
}));

/* Export
============================================================================= */

export default useBackgroundColor;
