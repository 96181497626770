import create from "zustand";

/* Use Three Color
============================================================================= */

function* activePatternLoop() {
    while (true) {
        yield 1;
        yield 2;
        yield 3;
        yield 4;
        yield 5;
    }
}

const activePatternGenerator = activePatternLoop();

const setRandomInitialPattern = () => {
    const number = Math.floor(Math.random() * 5) + 1;
    for (let i = 1; i <= number; i++) {
        activePatternGenerator.next();
    }
    return activePatternGenerator.next().value;
};

const [useThreeColor] = create((set) => ({
    bgColor: "rgb(27, 178, 149)",
    color: "rgb(198, 154, 244)",
    activePattern: setRandomInitialPattern(),
    setActivePattern: () =>
        set((state) => ({
            activePattern: activePatternGenerator.next().value,
        })),
    setBgColor: (bgColor) => set((state) => ({ bgColor })),
    setColor: (color) => set((state) => ({ color })),
}));

/* Export
============================================================================= */

export default useThreeColor;
