import create from "zustand";

/* Use  Newsletter
============================================================================= */

const [useNewsletter] = create((set) => ({
    newsletterOpen: false,
    setNewsletterOpen: (newsletterOpen) => set((state) => ({ newsletterOpen })),
}));

/* Export
============================================================================= */

export default useNewsletter;
