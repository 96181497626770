import React from "react";

/* Play Button
============================================================================= */

const PlayButton = () => {
    // Config

    // Render
    return (
        <svg
            height="100%"
            viewBox="0 0 111 111"
            id="launch-play-video"
            style={{
                fillRule: "evenodd",
                clipRule: "evenodd",
                strokeLinejoin: "round",
                strokeMiterlimit: 2,
            }}
        >
            <g transform="matrix(1,0,0,1,-100121,-112988)">
                <g transform="matrix(2,0,0,2,85671,104141)">
                    <g transform="matrix(1,0,0,1,7252.36,4426.64)">
                        <path
                            d="M0,49.034C-5.844,49.034 -11.215,46.975 -15.432,43.549L24.513,24.332C24.513,24.394 24.518,24.455 24.518,24.517C24.518,38.035 13.519,49.034 0,49.034M-24.517,24.517C-24.517,17.202 -21.293,10.63 -16.196,6.134L-15.734,43.301C-21.099,38.8 -24.517,32.051 -24.517,24.517M24.499,23.776L-15.662,5.671C-11.411,2.132 -5.95,0 0,0C13.271,0 24.104,10.599 24.499,23.776M0,-3C-15.197,-3 -27.517,9.32 -27.517,24.517C-27.517,39.714 -15.197,52.034 0,52.034C15.197,52.034 27.518,39.714 27.518,24.517C27.518,9.32 15.197,-3 0,-3"
                            style={{
                                fill: "rgb(249,245,237)",
                                fillRule: "nonzero",
                            }}
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};

/* Export
============================================================================= */

export default PlayButton;
