import { Link, useStaticQuery, graphql } from "gatsby";
import React from "react";
import useMenu from "../zustand/useMenu";
import useNewsletter from "../zustand/useNewsletter";
import useContactForm from "../zustand/useContactForm";
import useReelState from "../zustand/useReelState";
import useSmoothScroll from "../zustand/useSmoothScroll";
import AniLink from "gatsby-plugin-transition-link/AniLink";

/* Menu
============================================================================= */

const Menu = ({ location }) => {
    // Config
    const { open, setOpen } = useMenu();
    const { contactOpen, setContactOpen } = useContactForm();

    // Render
    return (
        <div
            style={{ zIndex: 100000 }}
            className={`menu-overlay fixed w-screen pointer-events-none h-full ${
                open ? "menu-open" : ""
            }`}
            onClick={() => setOpen(false)}
        >
            <div
                id="menu-panel"
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                <MenuLink
                    title="Agency"
                    activePathname={location.pathname}
                    pathName="/"
                />
                <MenuLink
                    title="Thoughts"
                    activePathname={location.pathname}
                    pathName="/thoughts/"
                />
                <button
                    onClick={() => {
                        setContactOpen(true);
                        setOpen(false);
                    }}
                >
                    <h1 className={`menu-link md:text-6xl text-3xl uppercase`}>
                        GET IN TOUCH
                    </h1>
                </button>
                <DigitalWavelengths />
            </div>
        </div>
    );
};

/* Menu Link
============================================================================= */

const MenuLink = ({
    title = "AGENCY",
    pathName = "/",
    activePathname = "/",
}) => {
    // Config
    const { setOpen } = useMenu();
    const { smooth, setSmooth } = useSmoothScroll();
    const { setReelOpen, setCanOpenReel } = useReelState();

    // Render
    return (
        <AniLink
            cover
            bg="#d3c846"
            duration={1}
            direction={activePathname === "/thoughts" ? "right" : "left"}
            to={pathName}
            className={`${
                activePathname === pathName ? "pointer-events-none" : ""
            }`}
            onClick={() => {
                smooth.unmount();
                if (title === "AGENCY") {
                    setReelOpen(false);
                    setCanOpenReel(false);
                }
                if (title === "THOUGHTS") {
                    setReelOpen(false);
                    setCanOpenReel(false);
                }
                setTimeout(() => {
                    window.scrollTop = 0;
                }, 500);
                setOpen(false);
            }}
        >
            <h1
                className={`menu-link md:text-6xl text-3xl uppercase ${
                    activePathname === pathName ? "menu-link-active" : ""
                }`}
            >
                {title}
            </h1>
        </AniLink>
    );
};

/* Digital Wavelengths
============================================================================= */

const DigitalWavelengths = () => {
    // Config
    const { setNewsletterOpen } = useNewsletter();
    const { setOpen } = useMenu();
    // Render
    return (
        <div className="flex flex-col items-end justify-start">
            <h4 className="digital-wavelengths">DIGITAL WAVELENGTHS:</h4>
            <div className="flex flex-row items-center justify-end">
                <a
                    href="https://www.facebook.com/mitp.agency"
                    target="_blank"
                    rel="norefferer noopener"
                >
                    <h2 className="social-text">Facebook</h2>
                </a>
                <div className="w-3 h-3 mx-3 bg-black" />
                <a
                    href="https://www.instagram.com/mitp.agency/"
                    target="_blank"
                    rel="norefferer noopener"
                >
                    <h2 className="social-text">Instagram</h2>{" "}
                </a>
            </div>
            <div className="flex flex-row items-center justify-end">
                <a
                    href="https://www.linkedin.com/company/mitpagency/"
                    target="_blank"
                    rel="norefferer noopener"
                >
                    <h2 className="social-text">Linkedin</h2>{" "}
                </a>
                <div className="w-3 h-3 mx-3 bg-black" />
                <a
                    href="https://www.behance.net/mitpagency"
                    target="_blank"
                    rel="norefferer noopener"
                >
                    <h2 className="social-text">Behance</h2>{" "}
                </a>
            </div>
            <h2
                className="text-right cursor-pointer subscribe-newsletter"
                onClick={() => {
                    setNewsletterOpen(true);
                    setOpen(false);
                }}
            >
                SUBSCRIBE TO OUR NEWSLETTER
            </h2>
        </div>
    );
};

/* Export
============================================================================= */

export default Menu;
