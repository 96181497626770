import React, { useState, useEffect, useRef } from "react";
import useNewsletter from "../zustand/useNewsletter";
import CloseIcon from "../svg/Close";
import axios from "axios";

/* Newsletter Mobile
============================================================================= */

const NewsletterMobile = () => {
    // Config
    const { newsletterOpen, setNewsletterOpen } = useNewsletter();
    const [email, setEmail] = useState("");

    // On Submit
    const onSubmit = async () => {
        await axios.post("./.netlify/functions/newsletter", {
            email,
        });
        setEmail("");
        setNewsletterOpen(false);
        alert("Subscribed!");
    };

    // Render
    return (
        <div
            id="newsletter-form-mobile"
            className={`fixed w-screen h-screen md:hidden flex flex-col items-start justify-start py-4 px-6 ${
                newsletterOpen ? "newsletter-form-mobile-visible" : ""
            }`}
        >
            <div
                id="newsletter-close"
                className="h-6 w-6"
                onClick={() => setNewsletterOpen(false)}
            >
                <CloseIcon />
            </div>
            <div className="flex flex-col items-start justify-start">
                <h2 className="lg:text-4xl text-2xl slide-heading uppercase leading-tight">
                    Subscribe to <br />
                    our newsletter
                </h2>
                <p className="slide-subheading pt-2 pb-6 lg:text-xl text-base w-4/5">
                    We won't annoy you too much, only once a month in fact!
                </p>
            </div>
            <div className="flex flex-col items-start justify-start w-full">
                <input
                    className="newsletter-input w-full"
                    placeholder="Email Address"
                    type="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <button
                    onClick={onSubmit}
                    className="form-button px-8 py-2 text-xl mt-8"
                >
                    SUBMIT
                </button>
            </div>
        </div>
    );
};

/* Export
============================================================================= */

export default NewsletterMobile;
