import React, { useState, useEffect, useRef } from "react";
import useNewsletter from "../zustand/useNewsletter";
import CloseIcon from "../svg/Close";
import axios from "axios";


/* Newsletter
============================================================================= */

const Newsletter = () => {
    // Config
    const { newsletterOpen, setNewsletterOpen } = useNewsletter();
    const [email, setEmail] = useState("");

    // On Submit
    const onSubmit = async () => {
        await axios.post("./.netlify/functions/newsletter", {
            email
        });
        setEmail("");
        setNewsletterOpen(false);
        alert("Subscribed!");
    }

    // Render
    return (
        <div
            id="newsletter-form"
            onClick={() => setNewsletterOpen(false)}
            className={`fixed w-screen h-screen flex items-center justify-center ${
                newsletterOpen ? "newsletter-form-visible" : ""
            }`}
        >
            <div
                id="newsletter-modal-container"
                className="px-8 py-6"
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                <div
                    id="newsletter-close"
                    className="w-6 h-6"
                    onClick={() => setNewsletterOpen(false)}
                >
                    <CloseIcon />
                </div>
                <div className="flex flex-col items-start justify-start">
                    <h2 className="text-3xl leading-tight uppercase lg:text-5xl slide-heading">
                        Subscribe to our <br />
                        newsletter
                    </h2>
                    <p className="w-4/5 pt-2 pb-6 text-base slide-subheading lg:text-xl">
                        We won't annoy you too much, only once a month in fact!
                    </p>
                </div>
                <div className="flex flex-row items-end justify-between">
                    <input
                        className="newsletter-input"
                        placeholder="Email Address"
                        type="email"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <button onClick={onSubmit} className="px-8 py-2 ml-8 text-xl form-button">
                        SUBMIT
                    </button>
                </div>
            </div>
        </div>
    );
};

/* Export
============================================================================= */

export default Newsletter;
