import create from "zustand";

/* Use  Reel State
============================================================================= */

const [useReelState] = create((set) => ({
    reelOpen: false,
    canOpenReel: false,
    showVideo: false,
    showPlayButton: false,
    showScroll: true,
    setShowScroll: (show) => set((state) => ({ showScroll: show })),
    setReelOpen: (reelOpen) =>
        set((state) => {
            if (state.canOpenReel) {
                return { reelOpen }
            }
        }),
    setCanOpenReel: (can) => set((state) => ({ canOpenReel: can })),
    setShowPlayButton: (show) => set((state) => ({ showPlayButton: show })),
    setShowVideo: (show) => set((state) => ({ showVideo: show })),
}));

/* Export
============================================================================= */

export default useReelState;
