import React, { useState, useEffect, useRef } from "react";
import useContactForm from "../zustand/useContactForm";
import Typed from "typed.js";
import _ from "lodash";
import CloseIcon from "../svg/Close";
import axios from "axios";

/* Contact Mobile
============================================================================= */

const ContactMobile = () => {
    // Config
    const {
        contactOpen,
        setContactOpen,
        email,
        phone,
        name,
        company,
        updateForm,
        activeFilters,
        toggleFilter,
        submit,
        additionalInfo,
    } = useContactForm();

    useEffect(() => {
        let typed = new Typed("#contact-mobile-typeout", {
            strings: ["progress", "waves", "leaps", "moves", "noise", "change"],
            smartBackspace: true,
            typeSpeed: 100,
            backSpeed: 50,
            loop: true,
            loopCount: Infinity,
        });
    }, []);

    // On Submit
    const onSubmit = async () => {
        const encode = (data) => {
            return Object.keys(data)
                .map(
                    (key) =>
                        encodeURIComponent(key) +
                        "=" +
                        encodeURIComponent(data[key])
                )
                .join("&");
        };
        await axios
            .post(
                "/",
                encode({
                    email,
                    phone,
                    name,
                    company,
                    description: additionalInfo,
                    video: _.includes(activeFilters, "Video"),
                    socialMedia: _.includes(activeFilters, "Social Media"),
                    augmentedReality: _.includes(
                        activeFilters,
                        "Augmented Reality"
                    ),
                    web: _.includes(activeFilters, "Web"),
                    content: _.includes(activeFilters, "Content"),
                    graphicDesign: _.includes(activeFilters, "Graphic Design"),
                    "form-name": "contact",
                }),
                {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                }
            )
            .then(() =>
                alert(
                    "Thanks for contacting us! We will get in touch with you shortly."
                )
            )
            .catch((error) => alert(error));
    };

    // Render
    return (
        <div
            id="contact-form-mobile"
            className={`fixed w-screen h-full flex flex-col md:hidden items-start justify-start   ${
                contactOpen ? "contact-mobile-visible" : ""
            }`}
        >
            <div
                id="contact-close"
                className="h-6 w-6"
                onClick={() => setContactOpen(false)}
            >
                <CloseIcon />
            </div>
            <div className="w-full py-4 px-6 overflow-y-scroll">
                <div className="w-full ">
                    <div className="flex flex-col items-start justify-start">
                        <h2 className="lg:text-4xl text-2xl slide-heading uppercase leading-tight">
                            Let's make <br />
                            some <span id="contact-mobile-typeout"></span>
                            <br className="md:hidden" /> together.
                        </h2>
                        <p className="slide-subheading pt-4 pb-6 lg:text-lg text-base md:w-4/5 w-full">
                            Let's get this ball rolling!{" "}
                            <br className="md:hidden" />
                            Complete the fields below
                            <br />
                            and we'll be in touch within{" "}
                            <br className="md:hidden" />
                            one business day.
                        </p>
                    </div>
                </div>
                <div className="flex flex-col items-start justify-start pb-32">
                    <FormInputContainer label="What should we call you?">
                        <FormTextInput
                            placeholder="Your Name Here"
                            type="text"
                            name="name"
                            value={name}
                            marginRight="0px"
                            onChange={updateForm}
                        />
                    </FormInputContainer>
                    <FormInputContainer label="Company / Organisation Name (optional)">
                        <FormTextInput
                            placeholder="Your Company Ltd"
                            type="text"
                            name="company"
                            value={company}
                            marginRight="0px"
                            onChange={updateForm}
                        />
                    </FormInputContainer>
                    <FormInputContainer label="How can we get in touch?">
                        <FormTextInput
                            placeholder="Email Address"
                            type="email"
                            name="email"
                            value={email}
                            marginRight="25px"
                            onChange={updateForm}
                        />
                        <div className="h-8" />
                        <FormTextInput
                            placeholder="Phone Number"
                            type="phone"
                            name="phone"
                            value={phone}
                            marginRight="0px"
                            onChange={updateForm}
                        />
                        <div className="h-2" />
                    </FormInputContainer>
                    <FormInputContainer label="What are you looking for?">
                        <div className="w-full grid grid-cols-1 gap-4 pt-2">
                            <ContactInterestItem
                                label="Video"
                                color="#FF5A23"
                                active={_.includes(activeFilters, "Video")}
                                onClick={toggleFilter}
                            />
                            <ContactInterestItem
                                label="Social Media"
                                color="#D3C846"
                                active={_.includes(
                                    activeFilters,
                                    "Social Media"
                                )}
                                onClick={toggleFilter}
                            />
                            <ContactInterestItem
                                label="Augmented Reality"
                                color="#C69AF2"
                                active={_.includes(
                                    activeFilters,
                                    "Augmented Reality"
                                )}
                                onClick={toggleFilter}
                            />
                            <ContactInterestItem
                                label="Web"
                                color="#48B0CC"
                                active={_.includes(activeFilters, "Web")}
                                onClick={toggleFilter}
                            />
                            <ContactInterestItem
                                label="Content"
                                color="#F49608"
                                active={_.includes(activeFilters, "Content")}
                                onClick={toggleFilter}
                            />
                            <ContactInterestItem
                                label="Graphic Design"
                                color="#1BB295"
                                active={_.includes(
                                    activeFilters,
                                    "Graphic Design"
                                )}
                                onClick={toggleFilter}
                            />
                        </div>
                    </FormInputContainer>
                    <FormTextArea />
                    <button
                        className={`form-button px-8 py-2  mt-8 text-xl ${
                            !activeFilters.length && !additionalInfo
                                ? "pointer-events-none opacity-50"
                                : ""
                        }`}
                        onClick={(e) => {
                            e.preventDefault();
                            setContactOpen(false);
                            onSubmit();
                            submit();
                        }}
                    >
                        DONE
                    </button>
                </div>
            </div>
        </div>
    );
};

/* Form Text Area
============================================================================= */

const FormTextArea = () => {
    // Config
    const { additionalInfo, updateForm } = useContactForm();
    // Render
    return (
        <>
            <p className="form-label lg:pb-4 pb-2 lg:text-xl text-xl pt-2">
                Any additional info?
            </p>

            <textarea
                className="w-full px-3 py-2 h-56"
                placeholder="Who do you want to target? What do you want to achieve? Any special requirements?"
                value={additionalInfo}
                onChange={(e) => updateForm("additionalInfo", e.target.value)}
                style={{
                    borderWidth: "2px",
                    borderColor: "black",
                    resize: "none",
                    backgroundColor: "transparent",
                    fontFamily: "Montserrat-Regular",
                }}
            ></textarea>
        </>
    );
};

/* Form Input Container
============================================================================= */

const FormInputContainer = ({ children, label }) => {
    // Config

    // Render
    return (
        <div className="form-input-container">
            <p className="form-label lg:pb-4 pb-2 lg:text-xl text-xl">
                {label}
            </p>
            <div className="flex md:flex-row flex-col md:items-center items-start md:justify-between justify-start">
                {children}
            </div>
        </div>
    );
};

/* Text Input
============================================================================= */

const FormTextInput = ({
    placeholder,
    type,
    name,
    value,
    marginRight,
    onChange,
}) => {
    // Config

    // Render
    return (
        <input
            className="form-text-input"
            placeholder={placeholder}
            type={type}
            name={name}
            value={value}
            onChange={(e) => onChange(name, e.target.value)}
            style={{
                marginRight,
            }}
        />
    );
};

/* Contact Interest Item
============================================================================= */

const ContactInterestItem = ({ label, color, active, onClick }) => {
    // Config

    // Render
    return (
        <div
            className="w-full px-3 py-2 cursor-pointer"
            onClick={() => onClick(label)}
            style={{
                backgroundColor: active ? color : "",
                borderWidth: "2px",
                borderColor: active ? color : "black",
            }}
        >
            <h4
                className="font-bold tracking-wide text-sm"
                style={{ fontFamily: "Montserrat-Regular" }}
            >
                {label}
            </h4>
        </div>
    );
};

/* Export
============================================================================= */

export default ContactMobile;
